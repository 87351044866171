import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import SEO from '../components/seo'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { SlugLink } from '../components/utils'

class BlogIndex extends React.Component {
    renderTags(tags) {
        tags.map((item) => {
            //console.log(item)
            return <Button variant="outline-secondary">{item}</Button>
        })
    }
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const siteDescription = data.site.siteMetadata.description
        const domainName = data.site.siteMetadata.domainName
        const posts = data.allMarkdownRemark.edges
        const { currentPage, numPages } = this.props.pageContext
        const isFirst = currentPage === 1
        const isLast = currentPage === numPages
        const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
        const nextPage = (currentPage + 1).toString()
        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={`${siteTitle} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    description={`${siteDescription} ${currentPage === 1 ? '' : `- Trang ${currentPage}`}`}
                    canonical={`https://${domainName}`}
                />
                <div className="row display-flex">
                {posts.map(({ node }) => {
                    let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid
                    const title = node.frontmatter.title || node.fields.slug
                    return (
                        <div class="col-md-4 post-list-h">
                            <div class="feature-12">
                            <Link to={node.fields.slug} title={title}>
                                <Img fluid={featuredImgFluid} objectFit="cover"
                                    objectPosition="50% 50%"
                                    alt={title} title={title} className="card-img-top" />
                                </Link>
                                <div class="feature__body">
                                    <h4><Link to={node.fields.slug} title={title}>{title}</Link></h4>
                                    <p className="lead" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                    <Link to={node.fields.slug} title={title} className="text-right">
                                        Chi tiết
                                    </Link>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>
                <div className="row">
                    <div className="col-12 text-center">
                        <div className="pagination">
                        <ol>
                            {!isFirst && (
                                <li className="w-pagination__link">
                                    <Link to={`/page/${prevPage}`} rel="prev" className="pagination__prev">
                                        
                                            ←
                                        
                                    </Link>
                                </li>
                            )}
                            {Array.from({ length: numPages }, (_, i) => (
                                <li
                                    key={`pagination-number${i + 1}`}
                                    className={i + 1 === currentPage ? 'pagination__current' : ''}
                                >
                                    <Link
                                        to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                                    >
                                    
                                            {i + 1}
                                        
                                    </Link>
                                </li>
                            ))}
                            {!isLast && (
                                <li className="pagination__next">
                                    <Link to={`/page/${nextPage}`} rel="next">
                        
                                            →
                                
                                    </Link>
                                </li>
                            )}
                        </ol>
                        </div>
                    </div>
                </div>
            </Layout >
        )
    }
}

export default BlogIndex

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!, $curdate: Date) {
                                site {
                                siteMetadata {
                                title
        domainName
                            description
                          }
                        }
                        allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC }
                            limit: $limit
                            skip: $skip
      filter: {frontmatter: {date: {lt: $curdate}}}
    ) {
                                edges {
                                node {
                                excerpt
          fields {
                                slug
                            }
                            frontmatter {
                                date(formatString: "DD MMMM, YYYY")
                            title
                            tags
                            category
            cover {
                                childImageSharp {
                                fluid(maxWidth: 800) {
                                ...GatsbyImageSharpFluid
                            }
                            }
                          }
                      }
                      html
                      timeToRead
                    }
                  }
                }
              }
            `
